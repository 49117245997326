import { usePermissions } from 'core/hooks/permissions';
import { Action } from 'core/types/permissions';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ClearFleetError from './ClearFleetError';

export interface CanAccessProps {
	// Resource name to check permission for
	// ex. 'docs', 'docs.specific-page'
	resource: string;

	// Action to check permission for
	// ex. 'read', 'write', 'delete'
	action: Action;

	// Primary key to check permission for
	// ex. Account ID, Field name, etc.
	primaryKey?: string;

	// Show the default denied component if permission is denied
	showError?: boolean;
}

export default function CanAccess({
	children,
	resource,
	action,
	primaryKey,
	showError,
}: PropsWithChildren<CanAccessProps>): JSX.Element | null {
	const { canAccess } = usePermissions();
	const { t } = useTranslation();

	// No permission
	if (!canAccess(resource, action, primaryKey)) {
		return showError ? (
			<ClearFleetError error={{ title: t('errors.403.title'), messages: [t('errors.403.message')] }} />
		) : null;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
