import { Alert, Divider, Grid, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ClearFleetForm, { Field, NumberField, ReadOnlyField, SelectField } from 'core/components/ClearFleetForm';
import FormControl from 'core/components/FormControl';
import { useAPI, usePermissions, useToast } from 'core/hooks';
import { ApiError, ServerValidation } from 'core/services/api';
import { numberFormat } from 'core/services/intl';
import { useFormik } from 'formik';
import { Namespace, TFunction } from 'i18next';
import IrpService from 'modules/irp/api/IrpService';
import WeightGroupsService from 'modules/irp/modules/weight_groups/api/WeightGroupsService';
import { Dispatch, FocusEventHandler, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fleet from 'types/Fleet';
import LookupValue from 'types/LookupValue';
import { OperatingJurisdiction } from 'types/OperatingJurisdiction';
import Permissions from 'types/Permissions';
import WeightGroup, {
	WeightGroupFields,
	WeightGroupMaxGrossWeight,
	WeightGroupMinGrossWeight,
	WeightGroupTrailerMaxWeight,
	WeightGroupType,
} from 'types/WeightGroup';
import * as Yup from 'yup';

type WeightGroupFormik = {
	id: number | null;
	fleet: Fleet | null;
	type: LookupValue | null;
	maxGrossWeight: number;
	description: string;
	operatingJurisdictions: OperatingJurisdiction[];
	varianceReason: string;

	// Computed
	atLeastOneOperatingJurisdiction?: string;
};

export interface WeightGroupFormProps {
	loading?: boolean;
	weightGroup?: WeightGroup | null;
	fleet?: Fleet | null;
	fleets?: Fleet[];
	type?: LookupValue | null;

	onFormik: (formik: ReturnType<typeof useFormik<WeightGroupFormik>>) => void;
	onSubmit: (data: WeightGroupFields, fleetKey?: string) => Promise<void> | void;
}

function WeightGroupInformationValidationSchema(t: TFunction<Namespace>) {
	const maxGrossWeightBetween = t('data.validation.between', {
		ns: 'core',
		min: numberFormat(WeightGroupMinGrossWeight),
		max: `${numberFormat(WeightGroupMaxGrossWeight)} ${t('data.units.pounds', { ns: 'core' })}`,
	});

	return {
		fleet: Yup.object({
			key: Yup.string().required(),
		}).required(t('data.validation.required', { ns: 'core' })),
		type: Yup.object<LookupValue>()
			.shape({
				id: Yup.number().required(),
			})
			.required(t('data.validation.required', { ns: 'core' })),
		maxGrossWeight: Yup.number()
			.required(t('data.validation.required', { ns: 'core' }))
			.min(WeightGroupMinGrossWeight, maxGrossWeightBetween)
			.max(WeightGroupMaxGrossWeight, maxGrossWeightBetween),
		description: Yup.string().required(t('data.validation.required', { ns: 'core' })),
		operatingJurisdictions: Yup.array()
			.of(
				Yup.lazy((value: OperatingJurisdiction) =>
					Yup.object().shape({
						jurisdiction: Yup.object().shape({
							id: Yup.number().required(),
						}),
						baseWeight: Yup.number()
							.default(0)
							.transform((v) => v || 0)
							.test(
								'minWeight',
								t('data.validation.gte', { ns: 'core', min: numberFormat(WeightGroupMinGrossWeight) }),
								// Test if the value is either 0, or greater than or equal to 2000
								(v) => v === 0 || (v || 0) >= WeightGroupMinGrossWeight,
							)
							.max(
								value.jurisdiction.maxWeight || WeightGroupMaxGrossWeight,
								t('data.validation.lte', {
									ns: 'core',
									max: numberFormat(value.jurisdiction.maxWeight || WeightGroupMaxGrossWeight),
								}),
							),
					}),
				),
			)
			.test('atLeastOne', (v, context) => {
				// At least one operating jurisdiction is required
				if (v?.some((oj) => oj.baseWeight > 0)) return true;

				return context.createError({
					path: 'atLeastOneOperatingJurisdiction',
				});
			}),
		varianceReason: Yup.string().when('operatingJurisdictions', {
			is: (v: OperatingJurisdiction[]) => {
				const weights = v.filter((oj) => oj.baseWeight > 0).map((oj) => oj.baseWeight);
				const minWeight = Math.min(...weights);
				const maxWeight = Math.max(...weights);
				return maxWeight > minWeight * 1.1;
			},
			then: (s) => s.required(t('data.validation.required', { ns: 'core' })),
			otherwise: (s) => s.strip(),
		}),
	};
}

export default function WeightGroupForm({
	loading: parentLoading,
	weightGroup,
	fleet,
	fleets,
	type,
	onFormik,
	onSubmit,
}: WeightGroupFormProps) {
	// Hooks
	const { t } = useTranslation('irp/weight_groups');
	const { openToast } = useToast();
	const weightGroupsService = useAPI(WeightGroupsService);
	const irpService = useAPI(IrpService);

	// State
	const [loading, setLoading] = useState({ types: false, jurisdictions: false });
	const [maxWeightsReached, setMaxWeightsReached] = useState<Record<number, boolean>>({}); // Max weight reached flags
	const [validationErrors, setValidationErrors] = useState<ApiError[]>([]); // Server-side validation errors
	const [types, setTypes] = useState<LookupValue[]>([]);

	// Validation schema
	const schema = WeightGroupInformationValidationSchema(t);
	schema.description = schema.description.test(
		'description',
		ServerValidation('description', 'WeightGroup.Description', validationErrors),
	);
	const validationSchema = Yup.object().shape(schema);

	// Form
	const formik = useFormik<WeightGroupFormik>({
		initialValues: {
			id: weightGroup?.id || null,
			fleet: fleet || null,
			type: type || weightGroup?.type || null,
			description: weightGroup?.description || '',
			maxGrossWeight: weightGroup?.maxGrossWeight || 0,
			operatingJurisdictions: weightGroup?.operatingJurisdictions || [],
			varianceReason: weightGroup?.varianceReason || '',
		},
		validationSchema,
		onSubmit: (data) => {
			if (!data) return undefined;

			// Clear max weights reached
			setMaxWeightsReached({});

			// Cast update for API
			const update = validationSchema.cast(data, { stripUnknown: true });
			const {
				fleet: { key },
				...finalWeightGroup
			} = update;

			return onSubmit(finalWeightGroup, key);
		},
	});

	// Set all jurisdiction weights
	const setAllWeights: FocusEventHandler<HTMLInputElement> = (e) => {
		// When changed, update all jurisdictions
		const newWeight = Number.parseInt(e.target.value.replace(/[^0-9]/g, ''), 10);
		if (!newWeight || newWeight < WeightGroupMinGrossWeight || newWeight > WeightGroupMaxGrossWeight) return;

		const newMaxWeightsReached: Record<number, boolean> = {};
		formik.values.operatingJurisdictions.forEach((oj, idx) => {
			// Set the field to the new weight, if less than that jurisdiction's max weight
			const { maxWeight } = oj.jurisdiction;
			const setWeight = maxWeight ? Math.min(newWeight, maxWeight) : newWeight;

			formik.setFieldValue(`operatingJurisdictions.${idx}.baseWeight`, setWeight, formik.submitCount > 0);

			// If the weight is greater than the max, set the max weight reached flag
			if (maxWeight && newWeight > maxWeight) {
				newMaxWeightsReached[idx] = true;
			}
		});

		// Set or clear max weights
		setMaxWeightsReached(newMaxWeightsReached);

		// Toast if any weights were adjusted
		if (Object.keys(newMaxWeightsReached).length > 0) {
			openToast({
				id: 'maxWeightReached',
				severity: 'warning',
				message: t('toasts.weightLimitsAdjusted'),
			});
		}
	};

	// Variance reason required?
	const varianceRequired = (() => {
		const weights = (formik.values.operatingJurisdictions || [])
			.filter((oj) => oj.baseWeight > 0)
			.map((oj) => oj.baseWeight);
		const minWeight = Math.min(...weights);
		const maxWeight = Math.max(...weights);
		return maxWeight > minWeight * 1.1;
	})();

	// Fleet field
	const fleetField: ReadOnlyField<WeightGroupFormik> | SelectField<WeightGroupFormik, Fleet> = (() => {
		const common = {
			name: 'fleet',
			label: t('fleet.number', { ns: 'data' }),
			required: true,
		};

		// Fleet is loading
		// Fleet is provided, readonly
		if (parentLoading || fleet) {
			return {
				...common,
				type: 'readonly',
				getValue: () => fleet?.number.toString() || '',
			};
		}

		// Only a single fleet, readonly

		return fleets?.length === 1
			? {
					...common,
					type: 'readonly',
					getValue: (v) => v.fleet?.number.toString() || '',
				}
			: {
					...common,
					type: 'select',
					getValue: (v) => v.fleet,
					options: fleets || [],
					selectProps: {
						getOptionKey: (v) => v.key,
						getOptionLabel: (v) => v.number.toString(),
					},
				};
	})();

	// Weight group type field
	const typeField: ReadOnlyField<WeightGroupFormik> | SelectField<WeightGroupFormik, LookupValue> = (() => {
		const common = {
			name: 'type',
			label: t('weight_group.type', { ns: 'data' }),
			required: true,
		};

		// Types are loading
		// Fleet is trailer only, readonly
		// Weight group has an ID, readonly - cannot change type
		// Type was overridden by parent, readonly
		if (parentLoading || loading.types || formik.values.fleet?.trailerOnly || formik.values.id || type) {
			return {
				...common,
				type: 'readonly',
				getValue: (v) => v.type?.displayName || '',
			};
		}

		return {
			...common,
			type: 'select',
			getValue: (v) => v.type || null,
			options: types,
			selectProps: {
				getOptionKey: (v) => v.id,
				getOptionLabel: (v) => v.displayName,
			},
		};
	})();

	// Base weight field
	const baseWeightField: ReadOnlyField<WeightGroupFormik> | NumberField<WeightGroupFormik> = (() => {
		const common = {
			name: 'maxGrossWeight',
			label: t('weight_group.maxGrossWeight', { ns: 'data' }),
			required: true,
		};

		// Loading
		// Weight group type is trailer
		if (parentLoading || formik.values.type?.code === WeightGroupType.TrailerUnit) {
			return {
				...common,
				type: 'readonly',
				getValue: (v) => (v.maxGrossWeight ? numberFormat(v.maxGrossWeight) : ''),
			};
		}

		return {
			...common,
			type: 'number',
			max: numberFormat(WeightGroupMaxGrossWeight).length,
			getValue: (v) => ((v.maxGrossWeight || 0) > 0 ? numberFormat(v.maxGrossWeight || 0) : ''),
			inputProps: {
				onBlur: setAllWeights,
			},
		};
	})();

	// Weight group form fields
	const weightGroupForm: Field<WeightGroupFormik>[] = [
		fleetField,
		typeField,
		{
			type: 'component',
			name: 'readonlyDivider',
			fullWidth: true,
			gridProps: { paddingTop: `0 !important` },
			component: null,
		},
		baseWeightField,
		{
			type: 'text',
			name: 'description',
			label: t('weight_group.description', { ns: 'data' }),
			max: 255,
			required: true,
			getValue: (v) => v.description || '',
		},
	];

	// Weight group changed from parent, set form fields
	useEffect(() => {
		// Set weight group once
		if (!weightGroup) return;

		formik.setValues(
			{
				...formik.values,
				...weightGroup,
			},
			false,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weightGroup]);

	// If weight group does not have operating jurisdictions,
	// generate them using the IRP jurisdictions
	useEffect(() => {
		if (formik.values.operatingJurisdictions && formik.values.operatingJurisdictions.length > 0) return;

		setLoading((prev) => ({ ...prev, jurisdictions: true }));
		irpService
			.listJurisdictions()
			.then((jurisdictions) => {
				// Set operating jurisdictions once
				formik.setFieldValue(
					'operatingJurisdictions',
					jurisdictions.map((jurisdiction) => ({
						jurisdiction,
						baseWeight: formik.values.type?.code === WeightGroupType.TrailerUnit ? WeightGroupTrailerMaxWeight : 0,
					})),
					false,
				);
			})
			.finally(() => setLoading((prev) => ({ ...prev, jurisdictions: false })));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.operatingJurisdictions, formik.values.type?.code]);

	// Load types
	useEffect(() => {
		setLoading((prev) => ({ ...prev, types: true }));
		weightGroupsService
			.getTypes()
			.then(setTypes)
			.finally(() => setLoading((prev) => ({ ...prev, types: false })));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Pass form handler up to parent
	useEffect(() => {
		onFormik(formik);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onFormik, formik.values]);

	// Fleet is trailer only, set weight group type
	useEffect(() => {
		if (!formik.values.fleet?.trailerOnly || !types) return;

		// Set type to trailer
		formik.setFieldValue(
			'type',
			types.find(({ code }) => code === WeightGroupType.TrailerUnit),
			false,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.fleet?.trailerOnly, types]);

	// Only a single fleet, select it
	useEffect(() => {
		if (!fleets || fleets.length !== 1) return;

		formik.setFieldValue('fleet', fleets[0], false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fleets]);

	// Weight group type set to trailer, set weights
	useEffect(() => {
		if (formik.values.type?.code !== WeightGroupType.TrailerUnit) return;

		// Set max gross weight to trailer max weight
		formik.setFieldValue('maxGrossWeight', WeightGroupTrailerMaxWeight, false);

		// Set all operating jurisdictions to trailer weight
		formik.setFieldValue(
			'operatingJurisdictions',
			formik.values.operatingJurisdictions.map((oj) => ({
				...oj,
				baseWeight: WeightGroupTrailerMaxWeight,
			})),
		);

		// Clear max weights since we changed base weight
		setMaxWeightsReached({});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.type?.code]);

	// Validate fields server-side
	useEffect(() => {
		if (!formik.values.description || !formik.values.fleet?.id) {
			return () => {
				/* do nothing */
			};
		}

		const tt = setTimeout(async () => {
			const { description } = formik.values;
			const errors = await weightGroupsService.validate(
				weightGroup?.key || null,
				{ description },
				formik.values.fleet?.id,
			);

			setValidationErrors(errors);
			if (errors.length > 0 || Object.keys(formik.errors).length > 0) {
				setTimeout(() => formik.validateForm(), 1);
			}
		}, 500);

		return () => {
			clearTimeout(tt);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.description]);

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			{/* Weight group info form */}
			<ClearFleetForm
				form={formik}
				loading={parentLoading || loading.types || formik.isSubmitting}
				fields={weightGroupForm}
			/>

			<Divider />

			{/* Operating jurisdictions */}
			<Box>
				<Typography variant="h4" gutterBottom>
					{t('weight_group.operatingJurisdictions.title', { ns: 'data' })}
				</Typography>
				<Typography variant="subtitle1" mb={2}>
					{t('weight_group.operatingJurisdictions.subtitle', { ns: 'data' })}
				</Typography>

				{/* eslint-disable-next-line no-use-before-define */}
				<OperatingJurisdictions
					formik={formik}
					loading={parentLoading || loading.jurisdictions || formik.isSubmitting}
					varianceRequired={varianceRequired}
					maxWeightsReached={maxWeightsReached}
					setMaxWeightsReached={setMaxWeightsReached}
				/>
			</Box>
		</Box>
	);
}

function OperatingJurisdictionsSkeleton() {
	return (
		<Grid container columnSpacing={12} rowSpacing={3}>
			{Array(5 * 3)
				.fill({})
				.map((_, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<Grid key={idx} xs={12} sm={4} md={4} lg={2.4} xl={2.4} item>
						{/* eslint-disable-next-line react/no-array-index-key */}
						<FormControl key={idx} name={`skeleton-${idx}`} label="&nbsp;">
							<Skeleton
								sx={{
									transform: 'unset',
									height: 43,
								}}
							/>
						</FormControl>
					</Grid>
				))}
		</Grid>
	);
}

interface OperatingJurisdictionsProps {
	formik: ReturnType<typeof useFormik<WeightGroupFormik>>;
	loading: boolean;
	varianceRequired: boolean;
	maxWeightsReached: Record<number, boolean>;
	setMaxWeightsReached: Dispatch<SetStateAction<Record<number, boolean>>>;
}

function OperatingJurisdictions({
	loading,
	formik,
	varianceRequired,
	maxWeightsReached,
	setMaxWeightsReached,
}: OperatingJurisdictionsProps) {
	// Hooks
	const { t } = useTranslation();
	const { canAccess } = usePermissions();
	const canEditVariance = canAccess(
		Permissions.IRP.WeightGroups.Fields.VarianceReason.resource,
		Permissions.IRP.WeightGroups.Fields.VarianceReason.actions.update,
	);

	// Loading
	if (loading && formik.values.operatingJurisdictions.length === 0) return <OperatingJurisdictionsSkeleton />;

	// Trailer
	if (formik.values.type?.code === WeightGroupType.TrailerUnit) {
		return <Alert severity="info">{t('weight_group.operatingJurisdictions.information', { ns: 'data' })}</Alert>;
	}

	const varianceReason: Field<WeightGroupFormik> = {
		type: 'text',
		name: 'varianceReason',
		label: t('weight_group.varianceReason', { ns: 'data' }),
		max: 255,
		required: true,
		disabled: !canEditVariance,
		tooltip: canEditVariance ? undefined : t('tooltips.edit-variance-permission', { ns: 'irp/weight_groups' }),
		getValue: (v) => v.varianceReason || '',
		gridProps: { xs: 12, lg: 8 },
	};

	// Jurisdiction fields
	const operatingJurisdictions = formik.values.operatingJurisdictions || [];
	const fields: Field<WeightGroupFormik>[] = operatingJurisdictions
		// Add country divider when country changes
		.reduce((acc, oj, idx) => {
			if (
				operatingJurisdictions[idx - 1] &&
				operatingJurisdictions[idx - 1].jurisdiction.state.countryId !== oj.jurisdiction.state.countryId
			) {
				acc.push({
					type: 'divider',
					name: `divider-${oj.jurisdiction.state.countryId}`,
				});
			}

			// Add jurisdiction field
			acc.push({
				type: 'number',
				name: `operatingJurisdictions.[${idx}].baseWeight`,
				label: oj.jurisdiction.state.name,
				max: numberFormat(oj.jurisdiction.maxWeight || WeightGroupMaxGrossWeight).length,
				required: true,
				getValue: () => numberFormat(oj.baseWeight),
				warning: maxWeightsReached[idx]
					? t('weight_group.operatingJurisdictions.validations.maxWeightReached', { ns: 'data' })
					: undefined,
				inputProps: {
					// Clear the max weight reached flag
					onChange: () => {
						setMaxWeightsReached((prev) => {
							const newMaxWeightsReached = { ...prev };
							delete newMaxWeightsReached[idx];
							return newMaxWeightsReached;
						});
					},
				},
				gridProps: { xs: 12, sm: 4, md: 4, lg: 12 / 5, xl: 12 / 5 },
				slotProps: {
					formControl: {
						error: formik.submitCount > 0 && !!formik.errors.atLeastOneOperatingJurisdiction,
					},
				},
			});

			return acc;
		}, [] as Field<WeightGroupFormik>[])
		// Append variance reason, if applicable
		.concat(varianceRequired ? varianceReason : []);

	// Jurisdictions form
	return <ClearFleetForm form={formik} loading={loading} fields={fields} />;
}
