import { Info, Search } from '@mui/icons-material';
import { Alert, Box, Divider, Input, Tooltip, Typography } from '@mui/material';
import StyledDataGrid from 'core/components/DataGrid';
import { useAPI } from 'core/hooks';
import { ApiError } from 'core/services/api';
import { currencyFormat } from 'core/services/intl';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { DraftInvoice, InvoiceVehicle } from 'modules/irp/modules/vehicles/api/Invoice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Supplement from 'types/Supplement';
import SupplementFeesColumns, { ColumnHeaders } from './SupplementFeesColumns';

export interface SupplementFeesDataGridProps {
	supplement: Supplement | null;
	columnHeaders?: ColumnHeaders[];
}

export default function SupplementFeesDataGrid({
	supplement,
	columnHeaders = ['base', 'foreign', 'admin', 'total'],
}: SupplementFeesDataGridProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements']);
	const supplementsService = useAPI(SupplementsService);

	// State
	const [loading, setLoading] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [invoice, setInvoice] = useState<DraftInvoice | null>(null);
	const [error, setError] = useState<ApiError | null>(null);

	const DataGrid = StyledDataGrid<InvoiceVehicle>();

	// Load data
	useEffect(() => {
		if (!supplement || !supplement.key) return;

		setLoading(true);
		supplementsService
			.getDraftInvoice(supplement.key)
			.then((inv) => setInvoice(inv || null))
			.catch(setError)
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplement]);

	// Table columns
	const columns = SupplementFeesColumns(columnHeaders);

	// Computed
	const filteredRows =
		(invoice?.vehicles.filter((vehicle) => {
			const { vin, unitNumber, titleNumber } = vehicle;
			const search = searchValue.toLowerCase();
			return (
				vin.toLowerCase().includes(search) ||
				unitNumber.toLowerCase().includes(search) ||
				titleNumber.toLowerCase().includes(search) ||
				(Math.round((vehicle?.fees?.base || 0) * 100) / 100).toFixed(2).includes(search) ||
				(Math.round((vehicle?.fees?.foreign || 0) * 100) / 100).toFixed(2).includes(search) ||
				(Math.round((vehicle?.fees?.admin || 0) * 100) / 100).toFixed(2).includes(search) ||
				(Math.round((vehicle?.fees?.iftaDecal || 0) * 100) / 100).toFixed(2).includes(search) ||
				(Math.round((vehicle?.fees?.total || 0) * 100) / 100).toFixed(2).includes(search)
			);
		}) as InvoiceVehicle[]) || ([] as InvoiceVehicle[]);

	const grandTotal = invoice?.vehicles.reduce((acc, vehicle) => {
		const { fees } = vehicle;
		return acc + (fees?.total || 0);
	}, 0);

	return (
		<>
			<Box display="flex" justifyContent="flex-end" mb={1}>
				<Input
					startAdornment={<Search color="primary" />}
					placeholder={`${t('buttons.search', { ns: 'core' })}...`}
					value={searchValue}
					onChange={(e) => setSearchValue(e.currentTarget.value)}
				/>
			</Box>
			<DataGrid
				className="striped"
				columns={columns}
				rows={filteredRows}
				loading={loading}
				columnHeaderHeight={65}
				slots={error ? { noRowsOverlay: ErrorOverlay } : undefined}
				slotProps={{ noRowsOverlay: { error } }}
				disableRowSelectionOnClick
			/>

			<Divider sx={{ my: 1, borderBottomWidth: 2 }} />

			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="subtitle2" display="flex" alignItems="center" gap={0.5} px={1.25}>
					{t('data.total', { ns: 'core' })}
					<Tooltip title={t('verify.tooltips.grandTotal', { ns: 'irp/supplements' })}>
						<Info fontSize="small" color="primary" />
					</Tooltip>
				</Typography>
				<Typography variant="subtitle2" display="flex" alignItems="center" px={1.25}>
					{grandTotal ? currencyFormat(grandTotal) : <span>&mdash;</span>}
				</Typography>
			</Box>
		</>
	);
}

// augment the props for the slot
declare module '@mui/x-data-grid' {
	interface NoRowsOverlayPropsOverrides {
		error: ApiError | null;
	}
}

function ErrorOverlay({ error }: { error: ApiError | null }) {
	const { t } = useTranslation('irp/supplements');
	const message = error?.statusCode === 408 ? t('verify.fees.unavailable') : error?.message;
	return (
		<Box display="flex" justifyContent="center" alignItems="center" height="100%">
			<Alert severity="warning">{message}</Alert>
		</Box>
	);
}
