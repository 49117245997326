/* eslint-disable max-classes-per-file */
import ApiService, { ApiErrorProps } from 'core/services/api';
import Vehicle from 'modules/ifta/types/Vehicle';
import Carrier, { CarrierContact, CarrierFields, CarrierIncludeFields } from 'types/Carrier';
import Fleet from 'types/Fleet';
import { IrpRegistrations } from 'types/IrpRegistration';
import Program from 'types/Program';

export default class CarriersService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/carriers`);
	}

	async list(query: ListSearchParams): Promise<Carrier[]> {
		const { carriers } = await this.GET<{ carriers: Carrier[] }>('', undefined, { params: query });
		return carriers;
	}

	async get(accountKey: string, include?: CarrierIncludeFields, opts?: ApiErrorProps): Promise<Carrier> {
		const { carrier } = await this.GET<{ carrier: Carrier }>(`/${accountKey}`, opts, {
			params: {
				include: include ? Object.keys(include).join(',') : undefined,
			},
		});
		return carrier;
	}

	async getContact(accountKey: string, program?: Program): Promise<CarrierContact> {
		return this.GET<CarrierContact>(`/${accountKey}/contact`, undefined, {
			params: { program },
		});
	}

	async getIrpRegistrations(accountKey: string): Promise<IrpRegistrations> {
		return this.GET<IrpRegistrations>(`/${accountKey}/irp`, undefined);
	}

	async update(key: string, carrier: CarrierFields): Promise<Carrier> {
		const response = await this.PUT<{ carrier: CarrierFields }, { carrier: Carrier }>(`/${key}`, { carrier });
		return response.carrier;
	}

	async create(userKey: string, fields: CarrierFields): Promise<Carrier> {
		const { carrier } = await this.POST<{ userKey: string; carrier: CarrierFields }, { carrier: Carrier }>('', {
			userKey,
			carrier: fields,
		});
		return carrier;
	}

	async getFleets(accountKey: string, expiryYear: number): Promise<Fleet[]> {
		const { fleets } = await this.GET<{ fleets: Fleet[] }>(`/${accountKey}/fleets`, undefined, {
			params: { expiryYear },
		});
		return fleets;
	}

	// Carrier IFTA routes
	IFTA = new CarrierIftaService();
}

class CarrierIftaService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/carriers`);
	}

	async list(accountKey: string, vin: string): Promise<Vehicle[]> {
		const path = `/${accountKey}/ifta/vehicles`;
		const { vehicles } = await this.GET<{ vehicles: Vehicle[] }>(path, undefined, {
			params: { vin },
		});
		return vehicles;
	}
}

export type ListSearchParams = Partial<Pick<Carrier, 'accountNumber'>>;
