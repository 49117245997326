import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import Permissions from 'types/Permissions';
import TransmittalRoutes from './routes';

const TransmittalsModule: Module = {
	// ID of the module
	id: 'transmittals',

	// Navigation bar items
	nav: [], // Empty to show module nav item without dropdown

	// Permissions
	permission: { resource: Permissions.Transmittals.resource, action: Actions.READ },

	// Routes
	routes: TransmittalRoutes,
};

export default TransmittalsModule;
