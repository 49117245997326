import { Address, AddressFields } from './Address';
import { Contact } from './Contact';
import { DistanceUnit } from './Country';
import Date, { DateRange } from './Date';
import { IrpJurisdiction } from './IrpJurisdiction';
import LookupValue, { LookupValueID } from './LookupValue';
import Tracking from './Tracking';

export const FleetNew = 'new';
export const FleetMaxMileage = 999_999_999;

export interface Mileage {
	id: number;
	jurisdiction: IrpJurisdiction;
	distance: number;
	percentage: number;
}

export enum MileageType {
	Actual = 'A',
	Estimated = 'E',
	IFTA = 'IFTA',
}

export enum FleetStatus {
	Active = 'A',
	Inactive = 'I',
}

export interface MileageFields {
	jurisdiction: { id: number };
	distance: number;
}

export default interface Fleet {
	id: number;
	key: string;
	number: number;
	startDate: Date;
	endDate: Date;
	status: LookupValue;
	tracking: Tracking;
	trailerOnly: boolean;
	type: LookupValue;
	activeVehicles: number;
	pendingVehicles: number;
	commodityClass: LookupValue;
	contact: Contact;
	mileage: Mileage[];
	mileageType: MileageType;
	distanceUnit: DistanceUnit;
	nonContiguousReason?: string;
	mileageReportingPeriod: DateRange;
	wyomingIntrastate?: boolean;
	iftaMileage: boolean;
	addresses: {
		business: Address;
		mailing?: Address;
	};
}

export interface FleetIncludes {
	mileage?: boolean;
}

export interface FleetActions {
	mileageType?: MileageType;
}

// mutable properties of Fleet
export interface FleetFields {
	mileage?: MileageFields[];
	contact?: Contact;
	type?: LookupValueID;
	commodityClass?: LookupValueID;
	trailerOnly?: boolean;
	wyomingIntrastate?: boolean;
	startDate?: Date;
	endDate?: Date;
	addresses?: {
		business?: AddressFields | null;
		mailing?: AddressFields | null;
	};
}
