import { isEqual } from 'core/services/intl';
import { DocumentType } from 'modules/documents/types/Document';
import Date from 'types/Date';
import Fleet from 'types/Fleet';
import LookupValue from 'types/LookupValue';
import { State, StateFields } from 'types/State';
import Tracking from 'types/Tracking';
import WeightGroup, { weightGroupHasChanged } from 'types/WeightGroup';

export const VehicleNew = 'new';

export interface VehicleCredential {
	issued?: Date;
	effective?: Date;
	expiration?: Date;
}

export enum VehicleBuyerType {
	Private = 'Private',
	Dealer = 'Dealer',
}

export enum VehicleFeeCalculationDate {
	Purchase = 'PurchaseDate',
	FirstOperated = 'FirstOperatedDate',
	Lease = 'LeaseDate',
	Other = 'OtherDate',
}

export type VehicleDocuments = Record<DocumentType, LookupValue | null>;

export default interface Vehicle {
	id: number;
	key: string;
	unitNumber: string;
	vin: string;
	type: LookupValue;
	fuelType: LookupValue;
	unladenWeight: number;
	factoryPrice: number;
	pullsTrailer: boolean;
	trailerExceedsMilesThreshold: boolean;
	supplementKey?: string;
	active: boolean;
	transferFromKey?: string;

	// Optional includes
	fleet?: Fleet;
	weightGroup?: WeightGroup;
	clonedFrom?: Vehicle;
	clonedFromKey?: string;

	plate?: {
		number: string;
		state?: State;
		type: LookupValue | null;
		expirationDate?: Date | null;
		return: LookupValue | null;
		personalized?: boolean;
		previous?: {
			number: string;
			state: State;
			expirationDate: Date;
		};
		requestNew: boolean;
	};

	model: {
		make: LookupValue | null;
		year: number;
		seats?: number;
	};

	axles: {
		unit: number;
		combined: number;
	};

	documents?: VehicleDocuments;

	purchase?: {
		date: Date | null;
		price: number;
		buyerType: LookupValue<VehicleBuyerType> | null;
		dealerName?: string;
		dealerState?: State;
	};

	registration: {
		issuedDate: Date | null;
		expirationDate: Date | null;
		owner: string;
		feeCalculationDate: LookupValue<VehicleFeeCalculationDate> | null;
		firstOperatedDate: Date | null;
		leaseDate: Date | null;
		otherDate: Date | null;
		tinOfMcrs: string;
		usdotOfMcrs: number;
		mcrsExpectedToChange: boolean;
		temporaryAuthorityNeeded: boolean;
		iftaDecalNeeded: boolean;
		mcoProvided: boolean;
		specialTruckStateFlags: {
			CO: boolean;
			UT: boolean;
		};
		backFeeAssessment?: {
			unpaidMonths: number;
			registeredWeight: number;
		};
	};

	title: {
		number: string;
		state: State;
	};

	credentials?: {
		temporaryAuthority?: VehicleCredential;
		cabCard?: VehicleCredential;
		plate?: VehicleCredential;
	};

	deactivate?: {
		date?: Date;
		reason?: LookupValue;
	};

	tracking: Tracking;
}

export interface VehicleSearchFields {
	supplementKey?: string;
	vin?: string;
	titleNumber?: string;
	unitNumber?: string;
	fleetKey?: string;
}

export interface VehicleIncludeFields {
	fleet?: true;
	weightGroup?: true;
	clonedFrom?: true;
}

export interface VehicleFields {
	unitNumber?: string;

	vin?: string;

	type?: LookupValue | null;

	fuelType?: LookupValue | null;

	unladenWeight?: number;

	factoryPrice?: number;

	pullsTrailer?: boolean;

	trailerExceedsMilesThreshold?: boolean;

	weightGroup?: Pick<WeightGroup, 'id'> | null;

	plate?: {
		number?: string;
		state?: StateFields | null;
		type?: LookupValue | null;
		expirationDate?: Date | null;
		return?: LookupValue | null;
		personalized?: boolean;
		previous?: {
			number?: string;
			state?: StateFields;
			expirationDate?: Date;
		};
		requestNew?: boolean;
	};

	model?: {
		make?: LookupValue | null;
		year?: number;
		seats?: number;
	};

	axles?: {
		unit?: number;
		combined?: number;
	};

	documents?: Partial<VehicleDocuments>;

	purchase?: {
		date?: Date | null;
		price?: number;
		buyerType?: LookupValue<VehicleBuyerType> | null;
		dealerName?: string;
		dealerState?: StateFields | null;
	};

	registration?: {
		owner?: string;
		feeCalculationDate?: LookupValue<VehicleFeeCalculationDate> | null;
		firstOperatedDate?: Date | null;
		leaseDate?: Date | null;
		otherDate?: Date | null;
		tinOfMcrs?: string;
		usdotOfMcrs?: number;
		mcrsExpectedToChange?: boolean;
		temporaryAuthorityNeeded?: boolean;
		iftaDecalNeeded?: boolean;
		mcoProvided?: boolean;
		specialTruckStateFlags?: {
			CO?: boolean;
			UT?: boolean;
		};
		backFeeAssessment?: {
			unpaidMonths?: number;
			registeredWeight?: number;
		};
	};

	title?: {
		number?: string;
		state?: State | null;
	};

	deactivate?: {
		date?: Date | null;
		reason?: LookupValue | null;
	};
}

export interface VehicleTransfer extends Vehicle {
	transferVehicle?: Vehicle;
}

interface NewVehicleProps {
	withPurchase?: boolean;
	withPlate?: boolean;
}

export function NewVehicle(props?: NewVehicleProps): VehicleFields {
	const { withPurchase, withPlate } = props || {};

	const newPurchase = (): Pick<VehicleFields, 'purchase'> => ({
		purchase: {
			buyerType: null,
			dealerName: '',
			dealerState: null,
		},
	});
	const newPlate = (): Pick<VehicleFields, 'plate'> => ({
		plate: {
			number: '',
			state: null,
			type: null,
			return: null,
		},
	});

	return {
		unitNumber: '',
		vin: '',
		type: null,
		fuelType: null,
		model: { make: null },
		title: { number: '', state: null },
		pullsTrailer: undefined,
		trailerExceedsMilesThreshold: undefined,
		registration: {
			firstOperatedDate: null,
			leaseDate: null,
			otherDate: null,
			owner: '',
			feeCalculationDate: null,
			mcrsExpectedToChange: false,
			temporaryAuthorityNeeded: true,
			iftaDecalNeeded: false,
			mcoProvided: false,
			specialTruckStateFlags: { CO: false, UT: false },
		},
		axles: {},
		...(withPurchase ? newPurchase() : {}),
		...(withPlate ? newPlate() : {}),
	};
}

function toVehicleComparisonFields(vehicle: Vehicle) {
	const {
		unitNumber,
		vin,
		type,
		fuelType,
		unladenWeight,
		factoryPrice,
		pullsTrailer,
		trailerExceedsMilesThreshold,
		plate,
		model,
		axles,
		purchase,
		registration,
		title,
	} = vehicle;

	// Explicitly compare Vehicle fields
	// These should usually match what is used in VehicleChanges.ts
	return [
		unitNumber,
		vin,
		purchase?.price,
		type,
		model.year,
		model.make,
		fuelType,
		title.number,
		title.state,
		registration.tinOfMcrs,
		registration.usdotOfMcrs,
		plate?.number,
		registration.mcrsExpectedToChange,
		plate?.personalized,
		plate?.requestNew,
		pullsTrailer,
		trailerExceedsMilesThreshold,
		unladenWeight,
		axles.unit,
		axles.combined,
		registration.owner,
		registration.feeCalculationDate,
		purchase?.date,
		registration.leaseDate,
		registration.otherDate,
		registration.firstOperatedDate,
		factoryPrice,
		purchase?.buyerType,
		purchase?.dealerName,
		purchase?.dealerState,
		plate?.previous,
		plate?.state,
		plate?.expirationDate,
		registration.specialTruckStateFlags.CO,
		registration.specialTruckStateFlags.UT,
		registration.mcoProvided,
	];
}

export function vehicleHasChanged(v1: Vehicle, v2: Vehicle): boolean {
	const { weightGroup: wg1, ...f1 } = v1;
	const { weightGroup: wg2, ...f2 } = v2;

	// Check fields equality
	if (!isEqual(toVehicleComparisonFields(f1), toVehicleComparisonFields(f2))) {
		return true;
	}

	// Weight group information must be included
	if (!wg1 || !wg2) throw new Error('Weight group information must be included when checking vehicle equality');

	// Check weight group equality
	return weightGroupHasChanged(wg1, wg2);
}
