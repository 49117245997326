import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import DateT from 'types/Date';

dayjs.extend(objectSupport);

// TODO This file needs to be cleaned up and standardized across all dates...

export const DateFormat = 'MM/DD/YYYY';

export const numberFormat = (value: number, decimals?: number) =>
	Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals || 0,
		maximumFractionDigits: decimals || 0,
	}).format(value);

export const phoneNumberFormat = (value: string) => {
	const phoneNumber = value.replace(/\D/g, '');

	// Format the phone number as (555) 555-5555
	let formattedNumber = '';

	[...phoneNumber].forEach((number, index) => {
		if (index === 0) formattedNumber += '(';
		else if (index === 3) formattedNumber += ') ';
		else if (index === 6) formattedNumber += '-';
		formattedNumber += number;
	});

	return formattedNumber.substring(0, 14);
};

export const currencyFormat = Intl.NumberFormat(navigator.language, {
	style: 'currency',
	currency: 'USD',
	currencySign: 'accounting',
}).format;

export const dateFormat = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: undefined,
	minute: undefined,
	second: undefined,
}).format;

export const dateFromTimestamp = (iso: string) => {
	const isISO = /Z$/.test(iso);
	const date = new Date(iso);
	const offset = (isISO ? new Date().getTimezoneOffset() : 0) * 60 * 1000;
	return new Date(date.getTime() + offset);
};

export const dateFromObject = (date?: DateT) => {
	if (!date) return undefined;
	return new Date(date.year, date.month - 1, date.day);
};

// dayjs: month is zero-indexed; January is 0
export const dayjsFromObject = (date?: DateT | null) => {
	if (!date) return undefined;

	const { year, month, day } = date || {};
	if (!year || !day || !month) return undefined;

	return dayjs({
		year,
		month: (month || 0) - 1,
		day,
	});
};

export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric',
}).format;

export const formatDateRange = (startDate: DateT, endDate: DateT) => {
	const start = dayjsFromObject(startDate)?.format(DateFormat);
	const end = dayjsFromObject(endDate)?.format(DateFormat);
	return `${start} - ${end}`;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (l: string) => `_${l.toLowerCase()}`);

export const isEqual = (obj1: unknown, obj2: unknown): boolean => {
	// Helper function to check if a value is an object
	const isObject = (obj: unknown): obj is Record<string, unknown> => {
		return obj !== null && typeof obj === 'object';
	};

	// If both values are not objects, compare them directly
	if (!isObject(obj1) || !isObject(obj2)) {
		return obj1 === obj2;
	}

	// If both are arrays, compare their lengths first
	if (Array.isArray(obj1) && Array.isArray(obj2)) {
		if (obj1.length !== obj2.length) {
			return false;
		}
	}

	// Get keys of both objects
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	// If the number of keys is different, the objects are different
	if (keys1.length !== keys2.length) {
		return false;
	}

	// Variable to store the result of the comparison
	let areEqual = true;

	// Compare each key and value in both objects
	keys1.forEach((key) => {
		// If key is not present in both objects, they are different
		if (!keys2.includes(key)) {
			areEqual = false;
			return;
		}

		// Recursive comparison of nested objects or arrays
		if (!isEqual(obj1[key], obj2[key])) {
			areEqual = false;
		}
	});

	return areEqual;
};

export const tinFormat = (value: string) => {
	// Format as ##-#######
	const tin = value.replace(/[^0-9]/g, '');
	if (tin.length <= 2) return tin;
	return `${tin.substring(0, 2)}-${tin.substring(2)}`;
};
