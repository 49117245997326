import { DateFormat, dayjsFromObject } from 'core/services/intl';
import dayjs from 'dayjs';
import { Namespace, TFunction } from 'i18next';
import { VehicleFeeCalculationDate } from 'modules/irp/modules/vehicles/types/Vehicle';
import Date, { DateRange, DateValidationSchema, DateValidations, TimeLocation } from 'types/Date';
import { LookupValueValidationSchema } from 'types/LookupValue';
import { SupplementType } from 'types/Supplement';
import * as Yup from 'yup';
import { getFCDMinMaxDates } from './FCDMinMaxDates';

export interface FCDValidationSchemaProps {
	t: TFunction<Namespace>;
	modelYear: number;
	registrationYear: DateRange;
	supplementType: SupplementType;
	deleteDate?: Date;
	effectiveDate?: Date;
	registrationSchema?: Yup.AnyObjectSchema;
	disableFODValidation?: boolean;
	disableRenewalMinDate?: boolean;
}

export function FCDValidationSchema({
	t,
	modelYear,
	registrationYear,
	supplementType,
	deleteDate,
	effectiveDate,
	disableFODValidation,
	disableRenewalMinDate,
}: FCDValidationSchemaProps) {
	// CONSTANTS
	const { fcdMinDate, fcdMaxDate, nonFcdMinDate, nonFcdMaxDate, regYearTimeLoc } = getFCDMinMaxDates({
		supplementType,
		registrationYear,
		deleteDate,
		effectiveDate,
		disableRenewalMinDate,
	});

	// Error message shortcuts
	const required = t('data.validation.required', { ns: 'core' });
	const sameMonthDelete = t('vehicles.errors.feeCalculationDate.sameMonthDelete', { ns: 'irp/supplements' });
	const firstMonth = t('vehicles.errors.feeCalculationDate.firstMonth', { ns: 'irp/supplements' });
	const withinRegPeriod = t('vehicles.errors.feeCalculationDate.withinRegPeriod', { ns: 'irp/supplements' });
	const afterRenewalMonth = t('vehicles.errors.feeCalculationDate.afterRenewalMonth', { ns: 'irp/supplements' });
	const withinRenewalMonth = t('vehicles.errors.feeCalculationDate.withinRenewalMonth', { ns: 'irp/supplements' });
	const afterRegStartMonth = t('vehicles.errors.feeCalculationDate.afterRegStartMonth', { ns: 'irp/supplements' });
	const maxDaysOrRegEnd = (days: number) =>
		t('vehicles.errors.feeCalculationDate.maxDaysOrRegEnd', { ns: 'irp/supplements', days });
	const defaultErrMessage = (days: number) => {
		return regYearTimeLoc === TimeLocation.Future
			? t('vehicles.errors.feeCalculationDate.firstMonth', { ns: 'irp/supplements' })
			: t('vehicles.errors.feeCalculationDate.maxDaysOrRegPeriod', { ns: 'irp/supplements', days });
	};

	// START Validation Base Tests
	const standardValidations = (s: Yup.ObjectSchema<Date>): Yup.ObjectSchema<Date> => {
		let schema = s;
		schema = schema.test('afterModelYear', modelYearTest(t, modelYear));
		return schema;
	};

	const fcdDateTest = (s: Yup.ObjectSchema<Date>, errorMessage: string): Yup.ObjectSchema<Date> => {
		return s.test('within FCD date range', DateValidations.withinDayJSRange(fcdMinDate, fcdMaxDate, errorMessage));
	};

	const nonFcdDateTest = (s: Yup.ObjectSchema<Date>, errorMessage: string): Yup.ObjectSchema<Date> => {
		return s.test(
			'within FCD date range',
			DateValidations.withinDayJSRange(nonFcdMinDate, nonFcdMaxDate, errorMessage),
		);
	};

	const baseTransferTest = (s: Yup.ObjectSchema<Date>): Yup.ObjectSchema<Date> => {
		const schema = s.test(
			'onOrAfterDeleteDate',
			minDateTest(t, 'vehicles.errors.feeCalculationDate.purchaseDateAfterDeleteDate', fcdMinDate),
		);
		return fcdDateTest(schema, sameMonthDelete);
	};

	const baseFleetTest = (s: Yup.ObjectSchema<Date>): Yup.ObjectSchema<Date> => {
		const timeLoc = regYearTimeLoc || TimeLocation.Current;
		const currentSchema = s.test(
			'onOrAfterEffectiveDate',
			minDateTest(t, 'vehicles.errors.feeCalculationDate.beforeEffectiveDate', fcdMinDate),
		);
		const fleetSchema = {
			[TimeLocation.Previous]: fcdDateTest(s, withinRegPeriod),
			[TimeLocation.Current]: fcdDateTest(currentSchema, maxDaysOrRegEnd(30)),
			[TimeLocation.Future]: fcdDateTest(s, firstMonth),
		};
		return fleetSchema[timeLoc];
	};
	// END Validation Base Tests

	// START Lease, Other and First Operated Date Tests
	// for each supp type, return test
	const registrationDatesTest = (fcdCode: VehicleFeeCalculationDate) => {
		// do not validate FOD when carrier doesn't use FOD:
		if (fcdCode === VehicleFeeCalculationDate.FirstOperated && disableFODValidation) {
			return DateValidationSchema.nullable();
		}

		switch (supplementType) {
			case SupplementType.AddFleet:
				return DateValidationSchema.when('feeCalculationDate.code', {
					is: fcdCode,
					then: (s) => {
						const schema = standardValidations(s);
						return baseFleetTest(schema).required(required);
					},
					otherwise: (s) => {
						const schema = standardValidations(s);
						return nonFcdDateTest(schema, maxDaysOrRegEnd(30)).nullable();
					},
				});

			// NewAccount dates can be up to 60 days in future
			case SupplementType.NewAccount:
				return DateValidationSchema.when('feeCalculationDate.code', {
					is: fcdCode,
					then: (s) => {
						const schema = standardValidations(s);
						return fcdDateTest(schema, firstMonth).required(required);
					},
					otherwise: (s) => {
						const schema = standardValidations(s);
						return nonFcdDateTest(schema, afterRegStartMonth).nullable();
					},
				});

			// Renewals dates can be up to 60 days in future
			case SupplementType.Renewal:
				return DateValidationSchema.when('feeCalculationDate.code', {
					is: fcdCode,
					then: (s) => {
						const schema = standardValidations(s);
						return disableRenewalMinDate
							? fcdDateTest(schema, afterRenewalMonth).required(required)
							: fcdDateTest(schema, withinRenewalMonth).required(required);
					},
					otherwise: (s) => {
						const schema = standardValidations(s);
						return nonFcdDateTest(schema, afterRenewalMonth).nullable();
					},
				});

			case SupplementType.TransferVehicle:
				return DateValidationSchema.when('feeCalculationDate.code', {
					is: fcdCode,
					then: (s) => {
						const schema = standardValidations(s);
						return baseTransferTest(schema).required(required);
					},
					otherwise: (s) => {
						const schema = standardValidations(s);
						return nonFcdDateTest(schema, maxDaysOrRegEnd(30)).nullable();
					},
				});

			// all other supplement types
			default:
				return DateValidationSchema.when('feeCalculationDate.code', {
					is: fcdCode,
					then: (s) => {
						const schema = standardValidations(s);
						return fcdDateTest(schema, defaultErrMessage(30)).required(required);
					},
					otherwise: (s) => {
						const schema = standardValidations(s);
						return nonFcdDateTest(schema, maxDaysOrRegEnd(30)).nullable();
					},
				});
		}
	};
	// END Lease, Other and First Operated Date Tests

	// START Purchase.date Tests
	// Apply to Purchase.date when selected as FCD:
	const fcdPurchaseDateTest = () => {
		const s = standardValidations(DateValidationSchema).test('noFutureDate', DateValidations.noFutureDate(t));
		switch (supplementType) {
			case SupplementType.AddFleet:
				return baseFleetTest(s);
			case SupplementType.TransferVehicle:
				return baseTransferTest(s);
			default:
				return fcdDateTest(s, withinRegPeriod);
		}
	};

	// Apply to Purchase.date when not selected as FCD
	const nonFcdPurchaseDate = standardValidations(DateValidationSchema)
		.test('noFutureDate', DateValidations.noFutureDate(t))
		.required(required);
	// END Purchase.date Tests

	return {
		feeCalculationDate: Yup.object().shape(LookupValueValidationSchema).required(required),
		leaseDate: registrationDatesTest(VehicleFeeCalculationDate.Lease),
		otherDate: registrationDatesTest(VehicleFeeCalculationDate.Other),
		firstOperatedDate: registrationDatesTest(VehicleFeeCalculationDate.FirstOperated),
		purchaseDate: fcdPurchaseDateTest().required(required),
		nonFcdPurchaseDate,
	};
}

// TABLE VALIDATIONS - apply to tables in AddDetailsStep, TransferDetailsStep, etc.
// only handles leaseDate, otherDate, and firstOpDate - purchaseDate is handled locally
interface FCDTableSchemaProps {
	t: TFunction<Namespace>;
	fcdMinDate: dayjs.Dayjs | undefined;
	fcdMaxDate: dayjs.Dayjs | undefined;
	errorMessage: string;
}
interface GenericFCDSchemaProps extends FCDTableSchemaProps {
	fcdCode: VehicleFeeCalculationDate;
}

export const fcdSchema = ({ t, fcdMinDate, fcdMaxDate, fcdCode, errorMessage }: GenericFCDSchemaProps) => {
	return DateValidationSchema.when('feeCalculationDate.code', {
		is: fcdCode,
		then: (s) =>
			s
				.required(t('data.validation.required', { ns: 'core' }))
				.test('valid fcd date', DateValidations.withinDayJSRange(fcdMinDate, fcdMaxDate, errorMessage)),
		otherwise: (s) => s.strip(),
	});
};

export const fcdTableSchemaFactory = ({ t, fcdMinDate, fcdMaxDate, errorMessage }: FCDTableSchemaProps) => {
	return {
		feeCalculationDate: Yup.object().shape(LookupValueValidationSchema),
		leaseDate: fcdSchema({ t, fcdMinDate, fcdMaxDate, errorMessage, fcdCode: VehicleFeeCalculationDate.Lease }),
		otherDate: fcdSchema({ t, fcdMinDate, fcdMaxDate, errorMessage, fcdCode: VehicleFeeCalculationDate.Other }),
		firstOperatedDate: fcdSchema({
			t,
			fcdMinDate,
			fcdMaxDate,
			errorMessage,
			fcdCode: VehicleFeeCalculationDate.FirstOperated,
		}),
	};
};

// standard test to verify date is not before vehicle model year
export const modelYearTest =
	(t: TFunction<Namespace>, modelYear: number) =>
	(data: Date | undefined, { createError, path }: Yup.TestContext) => {
		const d = dayjsFromObject(data);
		if (!d) return true;
		if ((d?.year() || 0) < modelYear - 1) {
			return createError({
				path,
				message: t('vehicles.errors.feeCalculationDate.modelYear', {
					ns: 'irp/supplements',
					year: modelYear - 1,
				}),
			});
		}
		return true;
	};

// standard test to verify date X does not come before date Y, with custom error message
export const minDateTest =
	(t: TFunction<Namespace>, tPath: string, minDate?: dayjs.Dayjs) =>
	(data: Date | undefined, { createError, path }: Yup.TestContext) => {
		if (!minDate) return true;
		const d = dayjsFromObject(data);
		return d && d.isBefore(minDate)
			? createError({
					path,
					message: t(tPath, {
						ns: 'irp/supplements',
						date: minDate.format(DateFormat),
					}),
				})
			: true;
	};
