import LookupValue, { LookupValueID } from 'types/LookupValue';
import { Address } from './Address';
import Date from './Date';
import Fleet from './Fleet';
import IrpRegistration, { IrpRegistrationFields } from './IrpRegistration';
import { WeightGroupChangeSupplementDetails, WeightGroupFields } from './WeightGroup';

export const SupplementNew = 'new';

export enum SupplementType {
	AddVehicle = 'AVE',
	EditVehicle = 'EV',
	DeleteVehicle = 'DEV',
	TransferVehicle = 'AXV',

	AddFleet = 'AFL',
	EditFleet = 'CHF',

	WeightGroupChange = 'CWT',

	NewAccount = 'AAC',

	Renewal = 'RWC',
}

interface SupplementBase {
	id: number;
	key: string;
	accountKey: string;
	type: LookupValue;
	status: LookupValue;
	number?: number;
	wip?: number;
	effectiveDate: Date;
	invoiceComments?: string;
	invoiceDelivery?: {
		method: LookupValue | null;
		details?: string;
	};
	physicalDelivery?: {
		method: LookupValue | null;
		details?: string;
		address?: Address;
	};

	// Associations
	fleet: Fleet;
}

type Supplement<T extends SupplementType | unknown = unknown> = SupplementBase & SupplementDetails<T>;

// prettier-ignore
type SupplementDetails<T extends SupplementType | unknown> =
	T extends SupplementType.WeightGroupChange ? {
			details: {
				weightGroupChange: WeightGroupChangeSupplementDetails;
			};
		} :
 T extends SupplementType.AddFleet ? {
			details: {
				addFleet: {
					irpRegistration: IrpRegistration;
				};
			};
		} :
 T extends SupplementType.NewAccount? {
			details: {
				newAccount: {
					irpRegistration: IrpRegistration;
				};
			};
		} :
 T extends SupplementType.Renewal ? {
			details: {
				renewal: {
					irpRegistration: IrpRegistration;
				};
			};
		} :
	unknown;
// prettier-enable

export default Supplement;

export interface SupplementFields {
	effectiveDate?: Date | null;
	invoiceComments?: string;
	invoiceDelivery?: {
		method?: LookupValue | null;
		details?: string;
	};
	physicalDelivery?: {
		method?: LookupValue | null;
		details?: string;
	};
}
export interface SupplementSubmitFormFields {
	approved?: boolean;
	submitted: boolean;
	termsCertified: boolean;
}

export interface SupplementActionFields {
	approved?: boolean;
	submitted: boolean;
}

export interface WeightGroupChange {
	id: number;
}

export interface CreateSupplementParams {
	accountKey: string | number;
	fleetKey: string;
	type: string;
	weightGroupChange: WeightGroupChange;
}

export interface FleetFields {
	type?: LookupValueID;
	commodityClass?: LookupValueID;
}

export interface CreateFields {
	weightGroupChange?: {
		weightGroup: { id: number };
		fields?: WeightGroupFields;
	};
	addFleet?: {
		irpAccountId: number;
	};
	editFleet?: {
		fleet: { id: number };
		fleetFields?: FleetFields;
	};
	newAccount?: {
		carrier: { id: number };
		irpRegistration: IrpRegistrationFields;
	};
	renewal?: {
		fleet: { id: number };
	};
}

export interface CreateSupplementRequest extends CreateFields {
	type: SupplementType;
}

export interface ListRestrictionsRequest extends CreateFields {
	type: SupplementType;
}

export enum SupplementStatus {
	Open = 'O',
	WorkInProcess = 'W',
	Processing = 'P',
	Invoiced = 'I',
	Remitted = 'RE',
	Paid = 'P',
	Closed = 'C',
	Denied = 'D',
}
