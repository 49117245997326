import { dayjsFromObject } from 'core/services/intl';
import { openToast } from 'core/services/toast';
import dayjs from 'dayjs';
import { Namespace, TFunction } from 'i18next';
import Vehicle, {
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleTransfer,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { DateRange, TimeLocation } from 'types/Date';

export function getFeeCalculationDate(vehicle: VehicleFields) {
	const feeCalculationDate = vehicle.registration?.feeCalculationDate?.code || '';
	const date = (() => {
		switch (feeCalculationDate) {
			case VehicleFeeCalculationDate.Purchase:
				return vehicle.purchase?.date ? vehicle.purchase.date : null;
			case VehicleFeeCalculationDate.FirstOperated:
				return vehicle.registration?.firstOperatedDate ? vehicle.registration.firstOperatedDate : null;
			case VehicleFeeCalculationDate.Lease:
				return vehicle.registration?.leaseDate ? vehicle.registration.leaseDate : null;
			case VehicleFeeCalculationDate.Other:
				return vehicle.registration?.otherDate ? vehicle.registration.otherDate : null;
			default:
				return null;
		}
	})();

	return date;
}

interface AllFCDInSameMonthProps {
	t: TFunction<Namespace>;
	vehicles: Vehicle[];
}

export const allFCDInSameMonth = ({ t, vehicles }: AllFCDInSameMonthProps) => {
	if (!vehicles.length) {
		openToast({
			id: 'supplements/vehicles/all-dates',
			message: t('vehicles.toasts.atLeastOne', { ns: 'irp/supplements' }),
			severity: 'error',
		});
		return false;
	}
	const firstDate = getFeeCalculationDate(vehicles[0]);
	const result = vehicles.every((v) => {
		const vDate = getFeeCalculationDate(v);
		return vDate?.month === firstDate?.month && vDate?.year === firstDate?.year;
	});

	if (!result) {
		openToast({
			id: 'supplements/vehicles/all-dates',
			message: t('vehicles.errors.feeCalculationDate.allDates', { ns: 'irp/supplements' }),
			severity: 'error',
		});
	}
	return result;
};

export const allTransferFCDInSameMonth = ({
	t,
	vehicles,
}: {
	t: TFunction<Namespace>;
	vehicles: VehicleTransfer[];
}) => {
	if (!vehicles.length) {
		openToast({
			id: 'supplements/vehicles/all-dates',
			message: t('vehicles.toasts.atLeastOne', { ns: 'irp/supplements' }),
			severity: 'error',
		});
		return false;
	}
	if (!vehicles[0].transferVehicle) return false;
	const firstDate = getFeeCalculationDate(vehicles[0].transferVehicle);
	const result = vehicles.every((v) => {
		if (!v.transferVehicle) return false;
		const vDate = getFeeCalculationDate(v.transferVehicle);
		return vDate?.month === firstDate?.month && vDate?.year === firstDate?.year;
	});

	if (!result) {
		openToast({
			id: 'supplements/vehicles/all-dates',
			message: t('vehicles.errors.feeCalculationDate.allDates', { ns: 'irp/supplements' }),
			severity: 'error',
		});
	}
	return result;
};

interface AllFCDInSameRegYearProps {
	t: TFunction<Namespace>;
	vehicles: Vehicle[];
	registrationYear: DateRange;
	fcdMinDate: dayjs.Dayjs | undefined;
	fcdMaxDate: dayjs.Dayjs | undefined;
	regYearTimeLoc: TimeLocation | undefined;
}

export const allFCDInSameRegYear = ({
	t,
	vehicles,
	registrationYear,
	fcdMinDate,
	fcdMaxDate,
	regYearTimeLoc,
}: AllFCDInSameRegYearProps) => {
	const failure = !!vehicles.find((val) => {
		const d = dayjsFromObject(getFeeCalculationDate(val));
		if (!d) return false;
		return regYearTimeLoc === TimeLocation.Future
			? d.isBefore(fcdMinDate) || d.isAfter(fcdMaxDate)
			: d.isBefore(dayjsFromObject(registrationYear.startDate)) || d.isAfter(dayjsFromObject(registrationYear.endDate));
	});

	if (failure) {
		openToast({
			id: 'supplements/add-fleet/dates-outside-reg-year',
			message:
				regYearTimeLoc === TimeLocation.Future
					? t('vehicles.errors.feeCalculationDate.firstMonthRegPeriod', { ns: 'irp/supplements' })
					: t('vehicles.errors.feeCalculationDate.withinRegPeriod', { ns: 'irp/supplements' }),
			severity: 'error',
		});
		return false;
	}

	return true;
};

// TOOLTIP ERROR GENERATION UTILITIES

type ErrorValue = string | { [key: string]: ErrorValue };
type ErrorObj = Record<string, ErrorValue>;

const flattenRecursively = (obj: ErrorObj, output: Map<string, string>): Map<string, string> => {
	Object.entries(obj).forEach(([k, v]) => {
		if (typeof v === 'string') {
			output.set(k, v);
			return null;
		}
		return flattenRecursively(v, output);
	});

	return output;
};

export const getFeeCalcTooltipError = (errors: Record<string, ErrorValue> | undefined) => {
	if (!errors) return '';
	const output = new Map<string, string>();
	flattenRecursively(errors, output);

	return (
		output.get('deactivate') ||
		output.get('date') ||
		output.get('leaseDate') ||
		output.get('purchaseDate') ||
		output.get('otherDate') ||
		output.get('firstOperatedDate')
	);
};
