import ApiService from 'core/services/api';
import { Permission } from 'core/types/permissions';
import Role from 'modules/admin/modules/users/types/Role';
import { UserType } from 'modules/admin/types/User';

export default class PermissionsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/permissions`);
	}

	async get(): Promise<Permission[]> {
		const { permissions } = await this.GET<{ permissions: Permission[] }>('');
		return permissions;
	}

	async listRoles({ userType }: { userType: UserType }): Promise<Role[]> {
		const { roles } = await this.GET<{ roles: Role[] }>('/roles', undefined, {
			params: { userType },
		});
		return roles;
	}

	async getRole(code: string): Promise<Role> {
		const { role } = await this.GET<{ role: Role }>(`/roles/${code}`);
		return role;
	}

	async setRolePermissions(code: string, permissions: Permission[]): Promise<void> {
		return this.PUT(`/roles/${code}`, { permissions });
	}
}
