import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import RazorPaths from 'modules/razor/paths';
import Permissions from 'types/Permissions';

const RefundsModule: Module = {
	// ID of the module
	id: 'refunds',

	// Navigation bar items
	nav: [
		{
			id: 'refundqueue',
			to: RazorPaths.RefundQueue.path,
		},
	],

	// Permission
	permission: { resource: Permissions.Refunds.resource, action: Actions.READ },
};

export default RefundsModule;
