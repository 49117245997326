import { Button, Typography } from '@mui/material';
import { PageContainer } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import { useTitle } from 'core/hooks';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { UserType } from 'modules/admin/types/User';
import RazorPaths from 'modules/razor/paths';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Home() {
	const { t } = useTranslation('home');
	const navigate = useNavigate();
	const currentUser = useCurrentUser();

	useTitle(`${t('title', { ns: 'home' })} - ${t('title', { ns: 'core' })}`);

	return (
		<PageContainer>
			<Typography variant="h3">{t('title', { ns: 'core' })}</Typography>

			{!currentUser.account && ![UserType.Administrator, UserType.Jurisdiction].includes(currentUser.type) && (
				<ClearFleetError error={{ title: t('account.incomplete.title'), messages: [t('account.incomplete.message')] }}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate(RazorPaths.Registration.Programs.buildPath({}))}
					>
						{t('account.incomplete.button')}
					</Button>
				</ClearFleetError>
			)}
		</PageContainer>
	);
}
