import { DateFormat, dayjsFromObject } from 'core/services/intl';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

// month is not zero-indexed: January is 1
export default interface Date {
	year: number;
	month: number;
	day: number;
}

export interface DateRange {
	endDate: Date;
	startDate: Date;
}

export const DateValidationSchema = Yup.object().shape({
	year: Yup.number().defined(),
	month: Yup.number().defined(),
	day: Yup.number().defined(),
});

export const MIN_DATE = dayjs(new Date(2000, 0, 1));
export const MAX_DATE = dayjs(new Date(new Date().getFullYear() + 1, 0, 1));

export const DateValidations = {
	noFutureDate:
		(t: TFunction<Namespace>) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			const d = dayjsFromObject(data);
			if (d && d.isAfter(dayjs())) {
				return createError({ path, message: t(`data.validation.datepicker.disableFuture`, { ns: 'core' }) });
			}
			return true;
		},

	// future dates valid when registration period has not started
	checkFutureDate:
		(t: TFunction<Namespace>, futureRegYear: boolean) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			if (futureRegYear) return true;
			const d = dayjsFromObject(data);
			if (d && d.isAfter(dayjs())) {
				return createError({ path, message: t(`data.validation.datepicker.disableFuture`, { ns: 'core' }) });
			}
			return true;
		},

	withinDateRange:
		(
			dateRange: DateRange | null,
			message: string | ((params: { value: dayjs.Dayjs; start: dayjs.Dayjs; end: dayjs.Dayjs }) => string),
		) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			if (!dateRange) return true;
			const value = dayjsFromObject(data);
			const start = dayjsFromObject(dateRange.startDate);
			const end = dayjsFromObject(dateRange.endDate);

			// don't validate until dates are assigned
			if (!value || !start || !end) return true;
			if (!value.isBetween(start, end, 'day', '[]')) {
				return createError({ path, message: typeof message === 'function' ? message({ value, start, end }) : message });
			}
			return true;
		},

	withinDayJSRange:
		(
			startDate: dayjs.Dayjs | undefined,
			endDate: dayjs.Dayjs | undefined,
			message: string | ((params: { value: dayjs.Dayjs; startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }) => string),
		) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			const value = dayjsFromObject(data);
			if (!value || !startDate || !endDate) return true;

			if (!value.isBetween(startDate, endDate, 'day', '[]')) {
				return createError({
					path,
					message: typeof message === 'function' ? message({ value, startDate, endDate }) : message,
				});
			}
			return true;
		},

	minDate:
		(t: TFunction<Namespace>, minDate?: dayjs.Dayjs) =>
		(data: Date | undefined, { createError, path }: Yup.TestContext) => {
			const d = dayjsFromObject(data);
			return d && d.isBefore(minDate || MIN_DATE)
				? createError({
						path,
						message: t(`data.validation.datepicker.minDate`, {
							ns: 'core',
							minDate: (minDate || MIN_DATE).format(DateFormat),
						}),
					})
				: true;
		},

	// for testing/debugging
	shouldFail:
		() =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_: any, { createError, path }: Yup.TestContext) => {
			return createError({ path, message: 'designed to fail' });
		},
};

export const dayjsToDate = (date?: dayjs.Dayjs): Date | null => {
	if (!date || !date.isValid()) return null;

	return {
		year: date.year(),
		month: date.month() + 1,
		day: date.date(),
	};
};

export const isOnOrAfter = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
	return d2.isSameOrAfter(d1, 'day');
};

export enum TimeLocation {
	Previous = 'previous',
	Current = 'current',
	Future = 'future',
}

export const getTimeLocation = (range: DateRange): TimeLocation => {
	const { startDate, endDate } = range;
	if (dayjs().isAfter(dayjsFromObject(endDate))) return TimeLocation.Previous;
	if (dayjs().isBefore(dayjsFromObject(startDate))) return TimeLocation.Future;
	return TimeLocation.Current;
};

export const todayPlus30 = dayjs().add(30, 'day');
