import { Box, Typography } from '@mui/material';
import { AuthorizedApp, Loader, PermissionsProvider } from 'core/components';
import Layout from 'core/components/Layout';
import { useAPI, useToast } from 'core/hooks';
import { useModuleNavigation } from 'core/hooks/navigation';
import { ReactComponent as IterisLogo } from 'core/images/iteris-logo.svg';
import { RazorProvider } from 'core/providers/RazorProvider';
import { ReactComponent as ClearFleetLogo } from 'images/clearfleet-logo.svg';
import modules from 'modules';
import UserService from 'modules/admin/modules/users/api/UserService';
import { CurrentUserProvider } from 'modules/admin/modules/users/components/CurrentUserContext';
import { HelpPath } from 'modules/home/routes/Help';
import permissions from 'modules/home/services/permissions';
import { ClientProvider } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { PropsWithChildren, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function AppWrapper({ children }: PropsWithChildren) {
	// Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const menuItems = useModuleNavigation(modules);

	// Example API to get user type
	const usersService = useAPI(UserService);
	const getUserType = useMemo(
		() => () =>
			usersService.v2
				.me()
				.then((resp) => t(`user.type.${resp.type}`, { defaultValue: resp.type }))
				.catch(() => {
					if (location.pathname !== '/error') {
						// TODO translate?
						navigate(`/error?error=${encodeURIComponent('Unable to load user type. Please try again.')}`);
					}

					return '';
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[], // No dependencies, only want to create this once
	);

	return (
		<AuthorizedApp>
			<PermissionsProvider provider={permissions}>
				<RazorProvider>
					<CurrentUserProvider>
						<Layout
							modules={modules}
							helpRoute={HelpPath.path}
							getUserType={getUserType}
							menuItems={menuItems}
							components={{
								logo: <ClearFleetLogo height={28} />,
								sidebar: {
									footer: (
										<Box
											display="flex"
											alignItems="flex-start"
											flexDirection="column"
											mt={1}
											py={1}
											px={2}
											rowGap={0.5}
										>
											<IterisLogo height={21} />
											<Typography color="Background" variant="caption">
												{t('title', { ns: 'core' })} {process.env.REACT_APP_VERSION}
											</Typography>
										</Box>
									),
								},
							}}
						>
							<ClientProvider>
								{/* TODO delay 500ms before showing the loader to prevent quick loader blips for fast responses */}
								<Suspense fallback={<Loader sx={{ flex: 1 }} />}>{children}</Suspense>
							</ClientProvider>
						</Layout>
					</CurrentUserProvider>
				</RazorProvider>
			</PermissionsProvider>
		</AuthorizedApp>
	);
}

export default function App() {
	useToast(); // Initialize toasts

	return (
		<AppWrapper>
			<Outlet />
		</AppWrapper>
	);
}
