import ApiService from 'core/services/api';
import Transmittal, { TransmittalStep } from 'modules/transmittals/types/Transmittal';
import Program from 'types/Program';

export default class TransmittalsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/transmittals`);
	}

	async list(program: Program, limit: number, offset: number): Promise<ListResponse> {
		const response = await this.GET<ListResponse>('', undefined, {
			params: { program, limit, offset },
		});

		return response;
	}

	async create(req: TransmittalCreateRequest): Promise<Transmittal> {
		const { transmittal } = await this.POST<TransmittalCreateRequest, { transmittal: Transmittal }>('', req);
		return transmittal;
	}

	async get(id: number): Promise<Transmittal> {
		const path = `/${id}`;
		const { transmittal } = await this.GET<{ transmittal: Transmittal }>(path);

		return transmittal;
	}

	async confirm(id: number): Promise<void> {
		const path = `/${id}/confirm`;
		return this.PATCH(path);
	}

	async publish(id: number, environment: TransmittalStep.Testing | TransmittalStep.Production): Promise<void> {
		const path = `/${id}/publish`;
		return this.PATCH(path, { environment: environment.replace(/^STEP_/i, '') });
	}

	async complete(id: number): Promise<void> {
		const path = `/${id}/complete`;
		return this.PATCH(path);
	}

	async generate(id: number, period?: Transmittal['period']): Promise<{ downloadUri: string; publishUri: string }> {
		const path = `/${id}/generate`;
		const resp = await this.PATCH<
			{
				period?: Transmittal['period'];
			},
			{ downloadUri: string; publishUri: string }
		>(path, { period });

		return resp;
	}

	async download(id: number, quiet?: boolean): Promise<{ downloadUri: string; publishUri: string }> {
		const path = `/${id}/download`;
		const resp = await this.GET<{ downloadUri: string; publishUri: string }>(path, {
			quiet,
		});

		return resp;
	}

	async delete(id: number): Promise<void> {
		const path = `/${id}`;
		return this.DELETE(path);
	}
}

export type TransmittalCreateRequest = Pick<Transmittal, 'program' | 'period'>;

export interface ListResponse {
	transmittals: Transmittal[];
	count: number;
	total: number;
}
