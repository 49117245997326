export enum UserType {
	Administrator = 'TYPE_ADMINISTRATOR',
	Jurisdiction = 'TYPE_JURISDICTION',
	ServiceProvider = 'TYPE_SERVICE_PROVIDER',
	Carrier = 'TYPE_CARRIER',
}

// TODO Update this

export default interface User {
	keyID: string;
	authUserID: number;
	firstName: string;
	lastName: string;
	email: string;
	title: string;
	phoneNumber: string;
	active: boolean;
	userType: UserType;
	roles: string[];
	serviceProvider?: number;
	carrier?: number;
}

// TODO: Remove old User interface
export interface UserV2 {
	id: number;
	key: string;
	authUserId: number;
	firstName: string;
	lastName: string;
	email: string;
	title: string;
	phone: string;
	active: boolean;
	roles: string[];
	type: UserType;

	// Optional, the account the User is associated with
	// Only set on Service Provider and Carrier accounts
	account?: {
		id: number;
		key: string;
		number: number;
		name: string;
	};
	// TODO: Update this?
	// serviceProvider?: number;
	// carrier?: number;
}

export interface UserFields {
	firstName?: string;
	lastName?: string;
	email?: string;
	title?: string;
	phone?: string;
	active?: boolean;
	type?: UserType;
	roles?: string[];

	// Account number to associate the user with (carrier or service provider)
	account?: {
		key: string;
	};
}
