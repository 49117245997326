import { Loader } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import UserService from 'modules/admin/modules/users/api/UserService';
import { UserType, UserV2 } from 'modules/admin/types/User';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

// TODO: This needs some love...
type CurrentUserContextProps = {
	currentUser: UserV2;
};

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined);

export function CurrentUserProvider({ children }: PropsWithChildren) {
	const userService = useAPI(UserService);
	const { isAuthenticated } = useAuth();
	const { setAccountKey } = useRazor();

	const [currentUser, setCurrentUser] = useState<UserV2>();
	const [error, setError] = useState<Error | null>(null);

	const value = useMemo<CurrentUserContextProps | undefined>(() => {
		if (!currentUser) return undefined;
		return { currentUser };
	}, [currentUser]);

	// Load user
	useEffect(() => {
		if (!isAuthenticated || !!currentUser) return;

		userService.v2.me().then(setCurrentUser).catch(setError);
	}, [isAuthenticated, currentUser, userService]);

	// CLEAR-2468: When user is set, check for assigned account
	// If account is assigned, set the Razor account key
	// This is required for pages like Weight Groups or Fleets
	// indexes that use the account key from the Razor provider
	useEffect(() => {
		if (!currentUser || !currentUser.account) return;

		// Only set for carriers
		if (currentUser.type !== UserType.Carrier) return;
		setAccountKey(currentUser.account.key);
	}, [currentUser, setAccountKey]);

	// Error
	if (error) return <ClearFleetError error={error} />;

	// Loading
	if (!currentUser) {
		return <Loader fullScreen />;
	}

	return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

export function useCurrentUser() {
	const context = useContext(CurrentUserContext);
	if (context === undefined) {
		throw new Error('useCurrentUser must be used within a CurrentUserProvider');
	}

	return context.currentUser;
}
