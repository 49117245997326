import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import FormControl from 'core/components/FormControl';
import InputBase from 'core/components/InputBase';
import { useAPI } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { useFormik } from 'formik';
import CarriersService from 'modules/accounts/api/CarriersService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface AccountLookupForm {
	accountNumber: number;
}

export default function AccountLookup() {
	// Hooks
	const { t } = useTranslation('accounts');
	const { setAccountKey } = useRazor();
	const carriersService = useAPI(CarriersService);

	// State
	const [loading, setLoading] = useState(false);

	const { values, errors, ...formik } = useFormik<AccountLookupForm>({
		initialValues: {
			accountNumber: 0,
		},
		validationSchema: Yup.object().shape({
			accountNumber: Yup.number()
				.max(99_999_999_999, t('data.validation.len.max', { ns: 'core', len: 11 }))
				.required(t('data.validation.required', { ns: 'core' })),
		}),
		onSubmit: (data) => {
			setLoading(true);

			return carriersService
				.list({
					accountNumber: data.accountNumber,
				})
				.then((res) => {
					if (res.length === 0) {
						formik.setFieldError('accountNumber', t('lookup.notFound'));
						return;
					}
					setAccountKey(res[0].key);
				})
				.finally(() => setLoading(false));
		},
	});

	return (
		<Box display="flex" flex={1} alignItems="center" justifyContent="center">
			<Grid container maxWidth="sm">
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h3" gutterBottom>
								{t('lookup.title')}
							</Typography>
							<Typography>{t('lookup.instructions')}</Typography>

							<Box mt={2}>
								<Box display="flex" gap={2}>
									<FormControl
										name="accountNumber"
										label={t('carrier.account_number', { ns: 'data' })}
										helperText={errors.accountNumber || t('data.validation.required', { ns: 'core' })}
										slotProps={{
											formControl: {
												error: !!errors.accountNumber,
												disabled: loading || formik.isSubmitting,
											},
										}}
									>
										<Box display="flex" gap={2}>
											<InputBase
												name="accountNumber"
												error={!!errors.accountNumber}
												value={values.accountNumber || ''}
												sx={{ flex: 1 }}
												onChange={formik.handleChange}
												onKeyUp={(e) => {
													if (e.key === 'Enter') formik.submitForm();
												}}
											/>
											<Button variant="contained" color="primary" onClick={() => formik.submitForm()}>
												{t('buttons.search', { ns: 'core' })}
											</Button>
										</Box>
									</FormControl>
								</Box>
							</Box>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
