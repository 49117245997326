import App, { AppWrapper } from 'App';
import ClearFleetError from 'core/components/ClearFleetError';
import { Route } from 'core/types/routing';
import modules from 'modules';
import { useTranslation } from 'react-i18next';
import { RouteObject, createBrowserRouter, matchRoutes, useLocation, useSearchParams } from 'react-router-dom';

function flatten(routes: Route<unknown>[]): RouteObject[] {
	return routes.map(({ path, children, ...rest }) => {
		return {
			path,
			children: children ? flatten(children) : undefined,
			...rest,
		} as RouteObject;
	});
}

export const routes = modules
	.filter((module) => module.routes)
	.map((module) => flatten(module.routes as Route<unknown>[]))
	.flat();

function RouteError() {
	const [params] = useSearchParams();
	const { t } = useTranslation();

	// If there's an error query param, show the error message
	if (params.get('error')) {
		const title = params.get('title') || t('errors.general.title');
		const messages = [params.get('error') || t('errors.500.message')];

		return <ClearFleetError error={{ title, messages }} />;
	}

	// Otherwise, show a 404
	return <ClearFleetError error={{ title: t('errors.404.title'), messages: [t('errors.404.message')] }} />;
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		handle: {
			// crumb: ({ t }: { t: TFunction }) => t('title'),
		},
		children: routes,
		errorElement: (
			<AppWrapper>
				<RouteError />
			</AppWrapper>
		),
	},
]);

export function useRoute() {
	const location = useLocation();
	const route = matchRoutes(routes, location)?.find((m) => m.pathname === location.pathname);

	return route;
}

export default router;
