import CheckIcon from '@mui/icons-material/Check';
import { styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ActionMenu from 'core/components/ActionMenu';
import StyledDataGrid from 'core/components/DataGrid';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import { Dayjs } from 'dayjs';
import { setIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import RemoveVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/RemoveVehicleDialog';
import EditVehiclesDialog from 'modules/irp/modules/supplements/modules/add_vehicle/components/EditVehicleDialog';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { getFCDMinMaxDates } from 'modules/irp/modules/supplements/types/FCD/FCDMinMaxDates';
import {
	allFCDInSameMonth,
	getFeeCalcTooltipError,
	getFeeCalculationDate,
} from 'modules/irp/modules/supplements/types/FCD/FCDUtilities';
import { fcdTableSchemaFactory } from 'modules/irp/modules/supplements/types/FCD/FCDValidations';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, {
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleNew,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { DateValidations, DateValidationSchema, dayjsToDate, TimeLocation } from 'types/Date';
import { SupplementType } from 'types/Supplement';
import * as Yup from 'yup';

const DataGrid = StyledDataGrid<Vehicle>();

const StyledButton = styled(Button)({ minWidth: 150 });

function NoRowsOverlay() {
	const { t } = useTranslation('irp/supplements/add_vehicle');
	return (
		<Box display="flex" justifyContent="center" alignItems="center" height="100%">
			<Typography variant="subtitle1">{t('details.no_rows_label')}</Typography>
		</Box>
	);
}

export default function AddDetailsStep() {
	// Hooks
	const { t } = useTranslation(['irp/supplements/add_vehicle', 'irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();
	const { supplementKey } = useTypedParams(AddVehiclePaths);
	const supplementsService = useAPI(SupplementsService);
	const vehiclesService = useAPI(VehiclesService);
	const { supplement } = useSupplement();

	const registrationYear = supplement?.fleet && {
		startDate: supplement.fleet.startDate,
		endDate: supplement.fleet.endDate,
	};

	const { fcdMinDate, fcdMaxDate, regYearTimeLoc } = getFCDMinMaxDates({
		supplementType: supplement.type.code as SupplementType,
		registrationYear,
	});

	const firstMonth = t('vehicles.errors.feeCalculationDate.firstMonth', { ns: 'irp/supplements' });
	const fcdWithinRegPeriod = t('vehicles.errors.feeCalculationDate.withinRegPeriod', { ns: 'irp/supplements' });
	const fcdSchemas = fcdTableSchemaFactory({ t, fcdMinDate, fcdMaxDate, errorMessage: firstMonth });
	const vehicleUpdateSchema = Yup.object().shape({
		registration: Yup.object({ ...fcdSchemas }),
		purchase: Yup.object().when('registration.feeCalculationDate.code', ([feeCalcCode], s) => {
			if (feeCalcCode !== VehicleFeeCalculationDate.Purchase) return s.strip();
			return s.shape({
				date: DateValidationSchema.required(t('data.validation.required', { ns: 'core' }))
					.test(DateValidations.checkFutureDate(t, regYearTimeLoc === TimeLocation.Future))
					.test('valid fcd date', DateValidations.withinDayJSRange(fcdMinDate, fcdMaxDate, fcdWithinRegPeriod)),
			});
		}),
	});

	// Form
	const { values, setValues, ...formik } = useFormik<Vehicle[]>({
		initialValues: [],
		validationSchema: Yup.array().min(1).of(vehicleUpdateSchema),
		onSubmit: () => navigate(AddVehiclePaths.Documentation.buildPath({ supplementKey })),
	});

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [rowsSaving, setRowsSaving] = useState<Record<string, boolean>>({});
	const [removeDialogVehicle, setRemoveDialogVehicle] = useState<Vehicle | null>(null);
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);

	// Computed
	const isSaving = Object.values(rowsSaving).find((v) => v);
	const selectedVehicles = values.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const getVehicles = useCallback(() => {
		return supplementsService
			.listAllVehicles(supplementKey, undefined, { onFirstPage: () => setTableLoading(false) })
			.then((vehiclesResp) => {
				setValues(vehiclesResp);
			});
	}, [supplementKey, setValues, supplementsService]);

	const loadVehicles = async () => {
		setTableLoading(true);
		await getVehicles();
		setTableLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		getVehicles().finally(() => setLoading(false));
	}, [supplementKey, getVehicles]);

	const updateVehicle = async (vehicleKey: string, field: string, value: unknown) => {
		const idx = values.findIndex((vehicle) => vehicle.key === vehicleKey);
		if (idx === -1) return;

		const errors = await formik.setFieldValue(`[${idx}].${field}`, value, true);
		const data: typeof values = setIn(values, `[${idx}].${field}`, value);

		// Invalid, skip
		if (errors && errors[idx] && Object.keys(errors[idx] || {}).length > 0) return;

		// Run after the formik state has been updated
		const vehicle = values[idx];
		try {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: true });

			const finalData = { ...data[idx], final: true };
			const updateFields = vehicleUpdateSchema.cast(finalData, {
				context: finalData,
				// Final cast to strip unknown fields, this is required to satisfy API minimum requirements
				stripUnknown: true,
			});
			if (!updateFields) return;

			await supplementsService.updateVehicle(supplementKey, vehicle.key, updateFields as VehicleFields);
		} finally {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: false });
		}
	};

	const handleNext = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'add-details-form-errors',
				message: t('errors.vehicle.ready', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		// All purchase dates must share the same month and year
		if (!allFCDInSameMonth({ t, vehicles: values })) return undefined;

		return formik.submitForm();
	};

	const handleRemoveVehicleConfirm = async () => {
		if (!removeDialogVehicle) return undefined;

		return vehiclesService.remove(removeDialogVehicle).then(() => {
			loadVehicles();
			setRemoveDialogVehicle(null);

			openToast({
				id: `remove-vehicle-dialog:${removeDialogVehicle.key}`,
				message: t('dialogs.remove_vehicle.removed', { ns: 'irp/supplements' }),
				severity: 'success',
			});
		});
	};

	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 180, flex: 1 },

		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 90,
			flex: 1,
		},

		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'title.number',
			minWidth: 90,
			flex: 1,
			valueGetter: ({ row }) => row.title.number,
		},

		{
			headerName: t('vehicle.registration.feeCalculationDate', { ns: 'data' }),
			field: 'registration.feeCalculationDate',
			minWidth: 180,
			flex: 1,
			valueGetter: ({ row }) => {
				const date = getFeeCalculationDate(row);
				return date ? dayjsFromObject(date) : null;
			},
			renderCell: ({ value, row }) => {
				const feeCalculationDate = row.registration?.feeCalculationDate?.code || '';
				const index = values.findIndex((vehicle) => vehicle.id === row.id);
				const error = getFeeCalcTooltipError(formik.errors[index]);
				const disableFuture = feeCalculationDate === VehicleFeeCalculationDate.Purchase;

				return (
					<Tooltip title={error}>
						<Box>
							<DatePicker
								value={value}
								onChange={(v: Dayjs | null) => {
									if (!v || !v.isValid() || !row.purchase) return;
									const date = dayjsToDate(v);

									switch (feeCalculationDate) {
										case VehicleFeeCalculationDate.Purchase:
											updateVehicle(row.key, 'purchase.date', date);
											break;
										case VehicleFeeCalculationDate.FirstOperated:
											updateVehicle(row.key, 'registration.firstOperatedDate', date);
											break;
										case VehicleFeeCalculationDate.Lease:
											updateVehicle(row.key, 'registration.leaseDate', date);
											break;
										case VehicleFeeCalculationDate.Other:
											updateVehicle(row.key, 'registration.otherDate', date);
											break;
										default:
											break;
									}
								}}
								slotProps={{
									textField: {
										variant: 'standard',
									},
								}}
								minDate={fcdMinDate}
								maxDate={fcdMaxDate}
								disableFuture={disableFuture}
							/>
						</Box>
					</Tooltip>
				);
			},
		},

		{
			headerName: t('data.ready', { ns: 'core' }),
			field: 'ready',
			headerAlign: 'center',
			align: 'center',
			width: 70,
			valueGetter: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);
				return !formik.errors[index];
			},
			renderCell: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);

				// Loading state
				if (rowsSaving[row.key]) return <CircularProgress size={24} />;

				// Valid state
				if (!formik.errors[index]) return <CheckIcon color="success" />;

				// Error state
				return <span>&mdash;</span>;
			},
		},

		{
			headerName: t('data.actions', { ns: 'core' }),
			field: 'action',
			headerAlign: 'center',
			sortable: false,
			align: 'center',
			width: 70,
			renderCell: ({ row }) => (
				<ActionMenu
					options={[
						{ id: 'edit', label: t('buttons.edit', { ns: 'core' }) },
						{ id: 'remove', label: t('buttons.remove', { ns: 'core' }) },
					]}
					onClick={({ id }) => {
						switch (id) {
							case 'edit':
								navigate(AddVehiclePaths.Vehicle.Info.buildPath({ supplementKey, vehicleKey: row.key }));
								break;
							case 'remove':
								setRemoveDialogVehicle(row);
								break;
							default:
								break;
						}
					}}
				/>
			),
		},
	];

	if (loading) return SupplementContentSkeleton;

	return (
		<>
			<form name="addDetailsForm" noValidate>
				<Box display="flex" flexDirection="column" rowGap={2}>
					<Card>
						<CardContent>
							<Typography variant="h3">{t('details.title')}</Typography>
							<Box mt={4} mb={2}>
								<DataGrid
									className="striped"
									columns={columns}
									rows={values}
									loading={tableLoading}
									slots={{ noRowsOverlay: NoRowsOverlay }}
									checkboxSelection
									rowSelectionModel={selectedVehicleIds}
									onRowSelectionModelChange={setSelectedVehicleIds}
									disableRowSelectionOnClick
								/>
							</Box>
							<Box display="flex" columnGap={1}>
								<StyledButton
									variant="outlined"
									onClick={() => navigate(AddVehiclePaths.Vehicle.buildPath({ supplementKey, vehicleKey: VehicleNew }))}
								>
									{t('dialogs.add_vehicle.title', { ns: 'irp/supplements' })}
								</StyledButton>
								{selectedVehicleIds.length > 0 && (
									<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
										{t('irp/supplements:dialogs.edit_vehicle.title', { count: selectedVehicleIds.length })}
									</StyledButton>
								)}
							</Box>
						</CardContent>
					</Card>
					<SupplementStepFooter
						nextLabel={t('buttons.next', { ns: 'core' })}
						nextDisabled={isSaving}
						onNext={handleNext}
					/>
				</Box>
			</form>

			<EditVehiclesDialog
				vehicles={selectedVehicles}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>

			<RemoveVehicleDialog
				vehicle={removeDialogVehicle}
				isOpen={!!removeDialogVehicle}
				setIsOpen={() => {
					setRemoveDialogVehicle(null);
				}}
				onConfirm={handleRemoveVehicleConfirm}
			/>
		</>
	);
}
